import React, { Suspense } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { LoadingText } from './components/LoadingMask/LoadingMask';
import MainDashboard from './pages/MainDashBoard/MainDashboard';
import { MiniPlayerContainer } from '@falconstudios/ns-player';

import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import Div100vh from 'react-div-100vh';
import { cleanupToken, hasTokenExpired } from './services/token-service/token.service';

import './styles/main.scss';
import './App.scss';
import './overrides/overrides.css';
import ImagesLightBoxStyle from './components/ImagesLighbox/ImagesLightBoxStyle';
import { HtmlContentProvider } from './context/HtmlContentContext';
import { WLConfigProvider } from './context/WLConfigContext';
import { AuthProvider } from './context/AuthContext';
import { NotificationProvider } from './context/NotificationsContext';

function App() {
  if (hasTokenExpired()) {
    cleanupToken();
  }

  return (
    <Div100vh>
      <div className="App">
        <Suspense fallback={<LoadingText />}>
          <WLConfigProvider>
            <AuthProvider>
              <HtmlContentProvider>
                <NotificationProvider>
                  <MainDashboard />
                </NotificationProvider>
              </HtmlContentProvider>
            </AuthProvider>
          </WLConfigProvider>
        </Suspense>
        <div id='MiniPlayerContainer'> {/* id is mandatory */}
          <MiniPlayerContainer />
        </div>
        <ImagesLightBoxStyle /> {/* MovieDetailsPage image gallery (fas fa-camera icon) */}
        <div id='ModalPortalRoot'></div>
      </div>
    </Div100vh>
  );
}

export default App;