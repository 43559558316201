import React from 'react';
import { isWeb } from '../../services/util-service/util.service';

import './MovieOverlay.scss';

export default function MovieOverlay(props) {
  const {
    topText,
    middleText,
    bottomText,
    btnText,
    btnColor,
    btnBgColor,
    btnPosition,
  } = props;

  const btnStyle = {};

  if (!isWeb()) {
    Object.assign(btnStyle, {
      color: btnColor,
      backgroundColor: btnBgColor
    })
  }

  const btnPositionClassName = () => {
    if (isWeb()) return '';

    let className = '';

    switch (btnPosition) {
      case 1:
        className = 'TopLeft';
        break;
      case 2:
        className = 'TopRight';
        break;
      case 3:
        className = 'BottomLeft';
        break;
      case 4:
        className = 'BottomRight';
        break;
      case 5:
        className = 'Center';
        break;
      default:
        className = 'Center';
    }

    return className;
  };

  return (
    <div className="MovieOverlay">
      <div className="TextWrapper">

        {isWeb() && topText && <div className="TopText">{topText}</div>}

        {isWeb() && middleText && (
          <div className="MiddleText">
            {middleText}
          </div>
        )}

        {isWeb() && bottomText && <div className="BottomText">{bottomText}</div>}

        <div className={`VideoBtn ${btnPositionClassName()}`} style={btnStyle}>{btnText ? btnText : 'Watch now'}</div>
      </div>
    </div>
  );
};