import React, { useState, useRef } from 'react';

import SceneImage from '../SceneImage/SceneImage';
import SinglePlayer from '../../pages/MainDashBoard/SinglePlayer/SinglePlayer';
import { useWLconfigContext } from '../../context/WLConfigContext';

import './SceneImageHoverPlayer.scss';

export default function SceneImageHoverPlayer(props) {
  const {
    alt,
    heroStillUrl,
    sampleVideoUrl
  } = props;

  const [state, setState] = useState({ isHovered: false, loading: false });
  const timeoutIdRef = useRef();
  const { wl_playerConfig: { progressBarColors } } = useWLconfigContext();

  const { isHovered, loading } = state;

  const onMouseOver = () => {
    setState((prev) => ({ ...prev, loading: true }));
    timeoutIdRef.current = setTimeout(() => {
      setState((prev) => ({ ...prev, isHovered: true, loading: false }));
    }, [1000]);
  };

  const onMouseLeave = () => {
    clearTimeout(timeoutIdRef.current);
    setState((prev) => ({ ...prev, isHovered: false, loading: false }));

    // to avoid infinite Player loop, TODO: rewrite @falconstudios/ns-player package
    const miniPlayer = document.getElementById('MiniPlayerContainer') || {};
    miniPlayer.innerHTML = '';
  };

  return (
    <div className='SceneImageContainer'>
      <SceneImage alt={alt} url={heroStillUrl} hidden={isHovered} />
      {isHovered && (
        <SinglePlayer
          src={sampleVideoUrl}
          poster={heroStillUrl}
          isPreview={true}
          loopPreviewVideo={true}
          isHoverPreview={true}
          muted={true} // backup for miniPlayer.innerHTML = ''
        />
      )}
      <div className='MouseEventOverlay' onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        {loading && (
          <div className='ProgressContainer' style={{ backgroundColor: progressBarColors.primary }}></div>
        )}
      </div>
    </div>
  );
}; 